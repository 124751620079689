.battery_sensor {
	background-color: #dde9fd;
	/* width: 28px;
	height: 28px; */
	padding: 0px 10px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}

.fullscreen_sensor {
	background-color: #dde9fd;
	width: 28px;
	height: 28px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}

.fullscreen_sensor:hover {
	background-color: #afcbfa;
}

.fullscreen_icon_sensor {
	color: #1065ef;
	font-size: 16px;
}

.sensors_page_pulse {
	animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
	}
	100% {
		box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
}
