.floor_sensor_icon {
	/* border: 2px solid green; */
	height: 28px;
	width: 28px;
	border-radius: 5px;
	background-color: #dde9fd;
	display: flex;
	justify-content: center;
	align-items: center;
}
.floor_sensor_icon_count {
	/* border: 2px solid green; */
	height: 28px;
	width: 28px;
	border-radius: 5px;
	background-color: #f7f7f7;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sensor_icon_count_detail {
	/* border: 2px solid green; */
	height: 50px;
	width: 50px;
	border-radius: 5px;
	background-color: #f7f7f7;
	display: flex;
	justify-content: center;
	align-items: center;
}

.title_bar_main_div {
	height: 100%;
	width: 100%;
	min-height: 2rem;
	padding: 0.6rem 0.6rem 0 0.6rem;
}
